body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.banner {
  font-weight: bold;
  margin-top: "5px";
  background-color: "black";
}

.content {
  line-height: "3px";
  line-height: "4px";
}

.input {
  width: 800px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input:read-only {
  color: gray;
  background-color: #eee;
  outline: none;
}

.player {
  width: 800px;
  margin: 0 auto;
}

.btn {
  margin: 2px 2px;
  padding: 2px 2px;
  width: 60px;
  height: 30px;
}

.video_container {
  background: white;
  height: 50vh;
  max-width: 800px;
  position: relative;
}

.video {
  width: 100%;
  height: 49vh;
  background-color: #ccc;
}

.status {
  margin-left: 1em;
}
